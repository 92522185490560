import { State } from 'apprise-frontend-core/state/api'
import { StateProvider } from 'apprise-frontend-core/state/provider'
import { createContext, PropsWithChildren } from 'react'
import { ExtensionState } from './state'


export type ExtensionProviderProps = PropsWithChildren


export const initialState : ExtensionState = {

}

export const ExtensionContext = createContext<State<ExtensionState>>(undefined!)

export const ExtensionProvider = (props:ExtensionProviderProps) => {

    const {children} = props


    return <StateProvider initialState={initialState} context={ExtensionContext}>
            {children}
    </StateProvider>
}