import { contactTypeCategory, specificationCategory, vesselGearCategory, vesselKindCategory, vesselRangeCategory, vesselTypeCategory } from '#details/constants'
import { useExtensions } from '#extension/state'
import { useAppSettings } from '#settings/settings'
import { VidExtension } from '#vid/ext'
import { useVID } from '#vid/model'
import { InputNumber } from 'antd'
import { useT } from 'apprise-frontend-core/intl/language'
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant'
import { Tenant, useTenantModel } from 'apprise-frontend-iam/tenant/model'
import { useTenantStore } from 'apprise-frontend-iam/tenant/store'
import { MultiTenantBox } from 'apprise-frontend-iam/tenant/tenantbox'
import { CategoryReference } from 'apprise-frontend-tags/category/model'
import { TagLabel } from 'apprise-frontend-tags/tag/label'
import { TagReference, useTagModel } from 'apprise-frontend-tags/tag/model'
import { useTagStore } from 'apprise-frontend-tags/tag/store'
import { useTagUtils } from 'apprise-frontend-tags/tag/utils'
import { classname } from 'apprise-ui/component/model'
import { DateBox } from 'apprise-ui/date/datebox'
import { MultiSelectBox, SelectBox } from 'apprise-ui/selectbox/selectbox'
import { TokenBox } from 'apprise-ui/tokenbox/tokenbox'
import { useEffect, useState } from 'react'
import "./fields.scss"
import { QueryFieldProps } from './model'



export const FlagField = (props: QueryFieldProps<{

    flags: TagReference[]

}>) => {

    const { children, label, onChange } = props

    const oracle = useTenancyOracle()
    const store = useTenantStore()
    const model = useTenantModel()
    const logged = useTenancyOracle()
    const excludeFlags = useAppSettings().excludedFlags ?? []

    const excludedButCurrent = excludeFlags.filter(t => t !== logged.tenant())

    const isIncluded = (t: Tenant) => logged.isTenantUser() ? !excludedButCurrent.includes(t.id) : true

    const tenants = (oracle.isTenantUser() ? store.allRefs() : store.all()).filter(isIncluded).sort(model.comparator)

    return <MultiTenantBox
        tenants={tenants}
        horizontal label={label} noClear placeholder={null} minWidth={200} onChange={flags => onChange?.(flags ? { flags } : undefined)}>
        {children?.flags}
    </MultiTenantBox>

}

export const NameField = (props: TokenizedFieldProps) => <TokenizedField {...props} field='names' />

export const TypeField = (props: TagFieldProps) => <TagField {...props} field='types' category={vesselTypeCategory} />

export const KindField = (props: TagFieldProps) => <TagField {...props} field='kinds' category={vesselKindCategory} />

export const GearField = (props: TagFieldProps) => <TagField {...props} field='gears' category={vesselGearCategory} />

export const RangeField = (props: TagFieldProps) => <TagField {...props} field='ranges' category={vesselRangeCategory} />

export const PortField = (props: TokenizedFieldProps) => <TokenizedField {...props} field='ports' />

type TagFieldProps = QueryFieldProps

const TagField = (props: TagFieldProps & { field: string, category: CategoryReference }) => {

    const { label, children, field, category, onChange } = props
    const model = useTagModel()

    const tags = useTagStore().allTagsOfCategory(category).sort(model.comparator)
    const textOf = useTagUtils().nameOf

    return <MultiSelectBox label={label} minWidth={200} horizontal

        options={tags.map(t => t.id)}
        render={t => <TagLabel bare noIcon tag={t} />}
        textOf={textOf}
        onChange={refs => onChange?.(refs ? { [field]: refs } : undefined)}>

        {children?.[field]}
    </MultiSelectBox>

}


type TokenizedFieldProps = QueryFieldProps

export const TokenizedField = (props: TokenizedFieldProps & { field: string }) => {

    const { children, field, label, onChange } = props

    return <TokenBox minWidth={200} label={label} horizontal noClear onChange={values => onChange?.(values ? { [field]: values } : undefined)}>
        {children?.[field]}
    </TokenBox>

}


type VidFieldProps = { vids: TagReference[] }
type IdFieldProps = { ids: TagReference[] }

export const VidField = (props: QueryFieldProps<VidFieldProps | IdFieldProps>
) => {

    const t = useT()


    const { allSchemes, parse, stringify } = useVID()

    const primaryScheme = useAppSettings().primaryScheme

    const vidextension = useExtensions<VidExtension>().get()

    const schemes = allSchemes(true)

    const fixedScheme = schemes.length === 1 && schemes[0]

    const schemeOptions = schemes.map(s => s.id)

    const { children, onChange } = props

    const vids = ((children as VidFieldProps)?.vids ?? (children as IdFieldProps)?.ids ?? []).map(parse)

    const values = vids.map(({ value }) => value!)

    const classes = classname('selectfield', fixedScheme && 'none')

    const [selectedScheme, setSelectedScheme] = useState(vids[0]?.scheme ?? primaryScheme ?? schemeOptions?.[0])

    // changes spec only if we have a min or max.
    const changeScheme = (scheme: TagReference | undefined) => {

        if (!scheme)
            return

        setSelectedScheme(scheme);

    }

    // rebase values on new scheme selection.
    useEffect(() =>

        changeVids(values),

        // eslint-disable-next-line
        [selectedScheme])

    const changeVids = (values: TagReference[] | undefined) => {

        if (!values || values.length === 0) {
            onChange?.(undefined)
            return
        }

        var vids = values.map(value =>

            selectedScheme === primaryScheme ? vidextension.postProcessValue(value) : value

        ).map(value => stringify(selectedScheme, value))


        onChange?.(selectedScheme === primaryScheme ? { ids: vids } : { vids: vids })


    }

    return <div className={classes}>

        {!!fixedScheme ||

            <SelectBox noSearch className={classes} noReadonly noClear options={schemeOptions} render={s => <TagLabel noIcon bare tag={s} title={s === primaryScheme ? t('search.pill_primary_label') : undefined} />} onChange={changeScheme}>
                {selectedScheme}
            </SelectBox>
        }

        <TokenBox minWidth={200} noClear label={fixedScheme ? fixedScheme?.name : undefined} onChange={changeVids}>
            {values}
        </TokenBox>
    </div>
}

export const SpecField = (props: QueryFieldProps<Partial<{ spec: TagReference, min: number, max: number }>>) => {

    const t = useT()

    const tags = useTagStore()

    const specs = tags.allTagsOfCategory(specificationCategory)

    const fixedSpec = specs.length === 1 && specs[0]

    const { children, onChange } = props

    const specOptions = specs.map(s => s.id)

    const classes = classname('selectfield', fixedSpec && 'mono')

    const initialSpec = children?.spec ?? specs[0]?.id

    const [selectedSpec, setSelectedSpec] = useState(initialSpec)

    // changes spec only if we have a min or max.
    const changeSpec = (spec: TagReference | undefined) => {

        if (!spec)
            return

        setSelectedSpec(spec);

        if (children?.min || children?.max)
            onChange?.({ ...children, spec })

    }

    // change min/max with current spec, if we have still a value to preserve overall.
    const changeMin = (min: number | null) => onChange?.(min || children?.max ? { ...children, spec: selectedSpec, min: min ?? undefined } : undefined)
    const changeMax = (max: number | null) => onChange?.(max || children?.min ? { ...children, spec: selectedSpec, max: max ?? undefined } : undefined)

    return <div className={classes}>

        {!!fixedSpec ||

            <SelectBox noSearch className={classes} noClear options={specOptions} render={s => <TagLabel noIcon bare tag={s} />} onChange={changeSpec}>
                {selectedSpec}
            </SelectBox>
        }
        <InputNumber prefix={<span className='prefix'>{t('search.pill_min')}</span>} value={children?.min} controls={false} onChange={changeMin} />
        <InputNumber prefix={<span className='prefix'>{t('search.pill_max')}</span>} value={children?.max} controls={false} onChange={changeMax} />

    </div>

}

export const ContactField = (props: QueryFieldProps<{ fuzzy: boolean, type: TagReference, contacts: TagReference[] }>) => {

    const tags = useTagStore()

    const types = tags.allTagsOfCategory(contactTypeCategory)

    const fixedType = types.length === 1 && types[0]

    const { children, onChange } = props

    const typeOptions = types.map(s => s.id)

    const classes = classname('selectfield', fixedType && 'mono')

    const initialContact = children?.type ?? types[0]?.id

    const [selectedType, setSelectedType] = useState(initialContact)

    // changes type only if we have some contacts
    const changeType = (type: TagReference | undefined) => {

        if (!type)
            return

        setSelectedType(type);

        if (children?.contacts)
            onChange?.({ ...children, type })

    }

    // change contacts with current spec, if we have still a value to preserve overall.
    const changeContacts = (contacts: string[] | undefined) => onChange?.(contacts ? { fuzzy: true, type: selectedType, contacts } : undefined)

    return <div className={classes}>

        {!!fixedType ||

            <SelectBox noSearch className={classes} flexWidth noClear options={typeOptions} render={s => <TagLabel noIcon bare tag={s} />} onChange={changeType}>
                {selectedType}
            </SelectBox>
        }

        <TokenBox separators={[]} minWidth={200} noClear label={fixedType ? fixedType?.name : undefined} onChange={changeContacts}>
            {children?.contacts}
        </TokenBox>

    </div>

}


export const AuthzField = (props: QueryFieldProps<{ date: string }>) => {

    const { children, label, onChange } = props

    return <DateBox label={label} horizontal onChange={date => onChange?.(date ? { date } : undefined)}>
        {children?.date}
    </DateBox>

}
