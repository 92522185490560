
import { useLanguage } from 'apprise-frontend-core/intl/language'
import { StateProvider } from 'apprise-frontend-core/state/provider'
import { PropsWithChildren, useEffect } from 'react'
import { useSearch } from './api'
import { defaultPageSize } from './constants'
import { SearchState, SearchStateContext } from './context'



export const initialSearchState: SearchState = {

    results: undefined!,        // no query executed
    query: undefined!,          // no query
    history: [],
    page: 1,
    pageSize: defaultPageSize,
    total: 0,
    sort: [{key:'timestamp', mode: 'desc'}]
}



export const Search = (props: PropsWithChildren) => {

    const { children } = props

    return <StateProvider initialState={initialSearchState} context={SearchStateContext}>
        <LanguageChangeObserver />
        {children}
    </StateProvider>
}


const LanguageChangeObserver = () => {

    const language = useLanguage().current()
    const api = useSearch()

    // re-runs query on lang change
    // (but only if we have sorted results that may be impacted by it).
    useEffect(() => {

        if (api.get().sort?.length > 0)
            api.runQueryQuietly()

    // eslint-disable-next-line
    }, [language])

    return null
}
