import { useExtensions } from '#extension/state'
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { PropsWithChildren } from 'react'
import { useIOTCVid } from './vid'



export const IOTCExtension = (props:PropsWithChildren) => {


    const extensions = useExtensions()
    const vidExtension = useIOTCVid()

    const activate = () => Promise.resolve( extensions.register(vidExtension))

    const {content} = useRenderGuard({

        render: props.children,
        orRun: activate

    })


    return content
}