import { Language, languageOf, useLanguage, useT } from 'apprise-frontend-core/intl/language'
import { Multilang } from 'apprise-frontend-core/intl/multilang'
import { FieldInfo } from './model'



export type ValidationStatus = 'success' | 'error' | 'warning'

export type Predicate<T = any> = (t: T) => Partial<FieldInfo> | undefined | false


export type ValidationCheck<T = any> = {

    predicate: (t: T) => boolean | [boolean, any]

    msg: (...args: any[]) => any

    status?: ValidationStatus
}



export const error = (msg: any): FieldInfo => ({ status: "error", msg })


export type FieldInfoHelpers = {

    hasErrors: () => boolean
    errors: () => number
    hasWarnings: () => boolean
}

export type Fields = Record<string, FieldInfo & Record<string,any>>
export type FieldReport<T extends Fields = Fields> = T & FieldInfoHelpers

// eslint-disable-next-line
export const asciiRegexp = /^[\x00-\x7F]+$/

export const useValidation = () => {

    const predicates = usePredicates()

    const self = {

        is: predicates

        ,

        reportOf: <T extends Fields = Fields>(info: T = {} as T): FieldReport<T> => {

            const self = {

                ...info
                ,

                errors: () => Object.values(info).filter(i => i.status === 'error').length

                ,

                hasErrors: () => self.errors() > 0


                ,

                hasWarnings: () => Object.values(info).filter(i => i.status === 'warning').length > 0

            }

            return self
        }

        ,

        check: <T>(p: Predicate) => {

            const _check = (...predicates: Predicate<T>[]) => {

                const self = {

                    and: (p: Predicate) => _check(...predicates, p)

                    ,

                    provided: (cond: boolean) => {
                       return _check(...cond ? predicates : predicates.slice(0,predicates.length-1))
                    }

                    ,

                    andProvided: (cond: boolean) => ({

                        check: (p: Predicate) => cond ? self.and(p) : self


                    })

                    ,

                    on: (o: T, postProcess: (_: FieldInfo) => FieldInfo = _ => _) => {

                        const outcome = predicates.map(p => p(o)).find(info => !!info)

                        return outcome && postProcess(outcome)

                    },

                    given: (o: T) => {

                        const outcome = self.on(o)

                        const clause = {

                            asWarning: () => clause.andFinishWith(o => ({ ...o, status: 'warning' })),
                            withMessage: (msg: string) => clause.andFinishWith(o => ({ ...o, msg })),
                            andFinishWith: (f: (_: FieldInfo) => FieldInfo) => outcome && f(outcome)
                        }

                        return clause

                    }

                }

                return self

            }


            return _check(p)
        }



    }

    return self;
}


export const invalidChars = " _:!?/\\*;,.@\t\b\v\n\f"
const reservedKeywords = ['new']
export const defaultMaxStringLength = 40


type PredicateDate = Date | string | number

export const useDatePredicates = () => {
    const t = useT()

    const internal = {

        toDate: (d: PredicateDate): Date => typeof d === 'string' || typeof d === 'number' ? new Date(d) : d,

        comparable: (d: PredicateDate) => internal.toDate(d)?.getTime(),

        format: (d: PredicateDate) => d && internal.toDate(d)?.toLocaleDateString()
    }

    const self = {

        //check if value inserted is not after a comparable
        notAfter: (dateToCompare: PredicateDate) => (value?: PredicateDate) => !value || internal.comparable(dateToCompare) < internal.comparable(value) ? error(t("ui.date_notafter_error", { comparable: internal.format(dateToCompare) })) : false

        ,

        //check if value inserted is not before a comparable
        notBefore: (dateToCompare: PredicateDate) => (value?: PredicateDate) => !value || internal.comparable(dateToCompare) > internal.comparable(value) ? error(t("ui.date_notbefore_error", { comparable: internal.format(dateToCompare) })) : false

        ,

        //check if value inserted is not in range of a and b
        notInRange: (a: PredicateDate, b: PredicateDate) => (value?: PredicateDate) => !value || (internal.comparable(value) < internal.comparable(a) || internal.comparable(value) > internal.comparable(b)) ? error(t("ui.date_notinrange_error", { a: internal.format(a), b: internal.format(b) })) : false
    }

    return self
}

const usePredicates = () => {

    const t = useT()
    const lang = useLanguage()

    const date = useDatePredicates()

    const self = {

        date

        ,

        true: (msg?:string| false) => typeof msg === 'string' && error(t(msg))

        ,

        empty: (s?: string | any[]) =>

            (typeof s === 'string' ? s?.trim().length === 0 : (!s || s.length === 0)) && error(t("ui.empty_error"))

        ,

        definedEmpty: (s?: string | any[]) => s !== undefined && self.empty(s)

        ,

        notDefined: (s?: any) => s===undefined && error(t("ui.empty_error"))

        ,

        duplicateWith: <T>(all: T[], uniqueCheck: (t: T) => any = t => t) => (e: T) => all.some(tt => uniqueCheck(e) === uniqueCheck(tt)) && error(t("ui.duplicate_error"))

        ,

        withKeywords: (s?: string | Multilang) => s && typeof s === 'string' ? reservedKeywords.includes(s.toLowerCase()) :

            Object.keys(s ?? {}).some(k => s?.[k] && reservedKeywords.includes(s?.[k]?.toLowerCase())) && t("ui.nokeyword_error")


        ,

        defaultTooLong: (s: string | Multilang) => self.tooLong()(s)

        ,

        tooLong: (length: number = defaultMaxStringLength) => (s: string | Multilang) =>

            (typeof s === 'string' ? s.length > length : Object.keys(s ?? {}).some(o => s[o]?.length > length)) && error(t("ui.too_long_error"))

        ,

        badEmail: (s: string) => !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(s) && error(t("ui.bad_email_error"))

        ,


        // ascii first, then minus invalid ones
        badChars: (chars: string = invalidChars) => (s?: string) => (!asciiRegexp.test(s ?? '') || s?.split('').some(c => chars.includes(c))) && error(t("ui.nochars_error"))

        ,


        noLanguages: (languages: Language[] = lang.required()) => (v: Multilang) =>

            v && languages.some(l => !!self.empty(v[l])) && error(languages.length === 1 ?

                languages.length === 1 ? t("ui.empty_error") : t("ui.required_language_error", { singular: t(languageOf(languages[0])) })
                :
                t("ui.required_languages_error", { plural: languages.map(l => t(languageOf(l))).join(",") })
            )

        ,


        duplicateLanguages: (all: (Multilang | undefined)[]) => (v: Multilang) => {

            const lang = all.map((other = {}) => Object.keys(other).find(lang => !!v?.[lang] && v[lang] === other[lang])).filter(l => !!l)?.[0]

            return !!lang && error(t("ui.duplicate_language_error", { singular: t(languageOf(lang as Language)) }))
        }

        ,

        changeText: (initial: (string | undefined)) => (v: string) => (initial !== undefined && initial !== v) && error(t("ui.change_code_error"))

        ,

       
        isNaN: (v: any | undefined) =>  (v && isNaN(Number(v)) && error(t("ui.nan_error")))

        ,

        number: {

            greaterThan: (max: number | undefined) => (v: any) =>( (v===undefined || max===undefined  || Number(v) > max) && error(t("ui.greater_than_error", { max })))

            ,

            greaterOrEqualsThan: (max: number | undefined) => (v: any) =>( (v===undefined || max===undefined  || Number(v) >= max) && error(t("ui.greater_than_error", { max })))

            ,
    
            smallerThan: (min: number | undefined) => (v: any) =>( (v===undefined || min===undefined || Number(v) < min)  && error(t("ui.smaller_than_error", { min })))

            ,

    
            smallerOrEqualThan: (min: number | undefined) => (v: any) =>( (v===undefined || min===undefined || Number(v) <= min)  && error(t("ui.smaller_than_error", { min })))

            ,

            outOfRange: (min: number | undefined, max: (number | undefined)) => (v: any) => ((( min !== undefined && Number(v)< min) || (max!==undefined && Number(v) > max))) && error(t("ui.outofrange_error", { min, max }))


        }

    }

    return self


}