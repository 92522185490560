
// a file with extractable data, with minimal metadata.
export type Resource = {

    id: ResourceRef
    name: string,
    file: Blob

}


export type ResourceRef = string


// options used internally by the framework to trigger/inihibit behviours.
export type ParseOptions = {

    emptyFileIssue: false | ParseIssue['type']
    emptySheetIssue: false | ParseIssue['type']
}

export const defaultParseOptions : ParseOptions = {

    emptyFileIssue: 'error',
    emptySheetIssue: 'warning'
}


// the context required for parsing, subsumes framework options. 
export type ParseContext<S> = Partial<ParseOptions> & S


// parsing contract: takse a resource and a contextual data and returns an outcome that includes data extracted from it.
export type ResourceParser<T,S> = (_: Resource, ctx: ParseContext<S>) => Promise<ResourceParseOutcome<T>>



export type ParseIssue = {

    message: string

    type: 'error' | 'warning'

    // interpreted in context, where applicable. typically an operation property.
    location?: string

} & Record<string,any>

// all the data produced by parsing a resources,  along with blocking errors and non-blocking issues.
export type ResourceParseOutcome<T> = {

    data: T[],
    issues: ParseIssue[]

}


export const emptyResourceParseOutcome = <T>(): ResourceParseOutcome<T> => ({ data: [], issues: [] })

// the intermediate application model that comes from parsing. 
export type JsonItem = any

// model parsing contract: transforms json items into data items in context.
export type ModelParser<T, S> = (json: JsonItem[], ctx:ParseContext<S>) => ResourceParseOutcome<T>

// model item parsing contract: transforms a json item into a data item in context. 
export type ItemParser<T, S> = (json: JsonItem, ctx: ParseContext<S>) => T



