import { imoscheme, ircsscheme, regnoscheme } from '#iotc/constants';
import { partyMembershipNCP } from '#modules/tenants';
import { RecordValidator, useGenericValidator } from '#record/validator';
import { RecordIssue } from '#submission/validator';
import { useVID } from '#vid/model';
import { useT } from 'apprise-frontend-core/intl/language';
import { useL } from 'apprise-frontend-core/intl/multilang';
import { utils } from 'apprise-frontend-core/utils/common';
import { useTenantStore } from 'apprise-frontend-iam/tenant/store';
import { useTagCache } from 'apprise-frontend-tags/cache';
import { contactTypeCategory, detailsType, grtTag, loaTag, operatorTag, ownerTag, portRegistryCategory, supplyVesselTypeTag, tonnageTag, totalVolumeTag, vesselGearCategory, vesselKindCategory, vesselRangeCategory, vesselTypeCategory } from './constants';
import { Contact, DetailsPatch, isCarrierVessel, isFishingVessel, isOperatingCompany } from './model';
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant';



export const minLoa = 0
export const maxLoa = 250
export const minTonnage = 0
export const maxTonnage = 10000
export const minGrt = 0
export const maxGrt = 10000
export const minVolume = 0
export const maxVolumeFV = 1000
export const maxVolumeCV = 20000

export const useDetailValidation = (): RecordValidator<DetailsPatch> => {

    const validator = useDetailValidator()

    return useGenericValidator(detailsType, validator)
}

export const useDetailValidator = (): RecordValidator<DetailsPatch> => {

    const t = useT()
    const l = useL()

    const tenants = useTenantStore()
    const vids = useVID()
    const logged = useTenancyOracle()

    const tagmap = useTagCache()


    return (data, submission) => {

        const imomap = utils().index(data.map(d => d.patch)).byGroup(t => vids.find(imoscheme, t.details?.identifiers.filter(vid => !vids.isSpecialValue(vid))))
        const regnomap = utils().index(data.map(d => d.patch)).byGroup(t => vids.find(regnoscheme, t.details?.identifiers.filter(vid => !vids.isSpecialValue(vid))))
        const ircsmap = utils().index(data.map(d => d.patch)).byGroup(t => vids.find(ircsscheme, t.details?.identifiers.filter(vid => !vids.isSpecialValue(vid))))
        const namemap = utils().index(data.map(d => d.patch)).byGroup(t => t.details?.name?.toLowerCase().trim())

        const tenant = tenants.lookup(submission.tenant)

        return data.reduce((acc, { patch, current }) => {

            const { details } = patch

            const issues: RecordIssue[] = []

            const imo = vids.find(imoscheme, details.identifiers)
            const currentimo = current ? vids.find(imoscheme, current?.details.identifiers) : undefined

            const regno = vids.find(regnoscheme, details.identifiers)

            const ircs = vids.find(ircsscheme, details.identifiers)

            if (!details.name)
                issues.push({ message: t('valid.missing_name'), type: 'error', location: "name" })

            if (details.name && namemap[details.name.toLowerCase().trim()].length > 1)
                issues.push({ message: t('valid.duplicate_name', { name: details.name, length: namemap[details.name.toLowerCase().trim()].length }), type: 'warning', location: "name" })

            if (!imo)
                issues.push({ message: t('valid.missing_imo'), type: 'error', location: imoscheme })

            if (imo && !vids.isSpecialValue(imo)) {

                if (!vids.isValidIMO(imo))
                    issues.push({ message: t('valid.invalid_imo'), type: 'error', location: imoscheme })


                if (currentimo && !vids.isSpecialValue(currentimo) && currentimo !== imo)
                    issues.push({ message: t('valid.changed_imo', { imo: currentimo }), type: logged.hasNoTenant() ? 'warning' : 'error', location: imoscheme })

                if (imomap[imo].length > 1)
                    issues.push({ message: t('valid.duplicate_imo', { imo, length: imomap[imo].length }), type: 'error', location: imoscheme })

            }


            if (!regno)
                issues.push({ message: t('valid.missing_regno'), type: 'error', location: regnoscheme })

            if (regno && !vids.isSpecialValue(regno)  && regnomap[regno].length > 1)
                issues.push({ message: t('valid.duplicate_regno', { regno, length: regnomap[regno].length }), type: 'error', location: regnoscheme })

            if (!ircs)
                issues.push({ message: t('valid.missing_ircs'), type: 'error', location: ircsscheme })

            if (ircs && !vids.isSpecialValue(ircs) && ircsmap[ircs].length > 1)
                issues.push({ message: t('valid.duplicate_ircs', { ircs, length: ircsmap[ircs].length }), type: 'error', location: ircsscheme })

            if (!details.vesselKind)
                issues.push({ message: t('valid.missing_kind'), type: 'error', location: vesselKindCategory })

            if (details.vesselKind && !tagmap.id2tag(details.vesselKind).of(vesselKindCategory))
                issues.push({ message: t('valid.invalid_kind', { kind: details.vesselKind }), type: 'error', location: vesselKindCategory })

            if (!details.vesselType)
                issues.push({ message: t('valid.missing_type'), type: 'error', location: vesselTypeCategory })

            if (details.vesselType && !tagmap.id2tag(details.vesselType).of(vesselTypeCategory))
                issues.push({ message: t('valid.invalid_type', { type: details.vesselType }), type: 'error', location: vesselTypeCategory })

            if (details.vesselType === supplyVesselTypeTag && details.vesselType !== current?.details.vesselType)
                issues.push({ message: t('valid.invalid_supply_type'), type: 'warning', location: vesselTypeCategory })

            if (!details.specifications[loaTag])
                issues.push({ message: t('valid.missing_loa'), type: 'error', location: loaTag })

            if (details.specifications[loaTag]){

                if  (parseFloat(details.specifications[loaTag]) <= minLoa )
                    issues.push({ message: t('valid.invalid_loa'), type: 'error', location: loaTag })
                else

                if (parseFloat(details.specifications[loaTag]) > maxLoa)
                   issues.push({ message: t('valid.invalid_loa_range', { max: maxLoa }), type: 'warning', location: loaTag })


            } 
            

            if (!details.specifications[tonnageTag] && (!current || !current.details.specifications[grtTag]))
                issues.push({ message: t('valid.missing_tonnage'), type: 'error', location: tonnageTag })

            if (details.specifications[tonnageTag]) {

                if (parseFloat(details.specifications[tonnageTag]) <= minTonnage )
                    issues.push({ message: t('valid.invalid_tonnage'), type: 'error', location: tonnageTag })

                else if (parseFloat(details.specifications[tonnageTag]) > maxTonnage)
                    issues.push({ message: t('valid.invalid_tonnage_range', { max: maxTonnage }), type: 'warning', location: tonnageTag })

            } 

            if (!details.specifications[totalVolumeTag])
                issues.push({ message: t('valid.missing_totvol'), type: 'error', location: totalVolumeTag })

            if (details.specifications[totalVolumeTag]) {

                if (parseFloat(details.specifications[totalVolumeTag]) <= minVolume)  
                issues.push({ message: t('valid.invalid_totvol'), type: 'error', location: totalVolumeTag })

                else 

                if (parseFloat(details.specifications[totalVolumeTag]) > maxVolumeFV)
                    issues.push({ message: t('valid.invalid_totvol_range', { max: maxVolumeFV }), type: 'warning', location: totalVolumeTag })

            } 

            // FV-only checks.
            if (isFishingVessel(patch)) {

                // if (!details.specifications[totalVolumeTag])
                //     issues.push({ message: t('valid.missing_totvol'), type: 'error', location: totalVolumeTag })

                // if (details.specifications[totalVolumeTag]) {

                //     if (parseFloat(details.specifications[totalVolumeTag]) <= minVolume)  
                //     issues.push({ message: t('valid.invalid_totvol'), type: 'error', location: totalVolumeTag })

                //     else 

                //     if (parseFloat(details.specifications[totalVolumeTag]) > maxVolumeFV)
                //         issues.push({ message: t('valid.invalid_totvol_range', { max: maxVolumeFV }), type: 'warning', location: totalVolumeTag })

                // } 

                if (!details.range)
                    issues.push({ message: t('valid.missing_range'), type: 'error', location: vesselRangeCategory })

                if (!details.gears?.length)
                    issues.push({ message: t('valid.missing_gear'), type: 'error' });

                if (!details.port)
                    issues.push({ message: t('valid.missing_port'), type: 'error', location: portRegistryCategory })

                // kind check: a non-CPC party may only update carrier vessels.
                if (details.vesselType && tenant?.tags.includes(partyMembershipNCP))
                    issues.push({ message: t('valid.invalid_fv_party', { party: l(tenant.name), type: details.vesselType }), type: 'error', location: vesselTypeCategory })

            }

            // CV-only checks.
            if (isCarrierVessel(patch)) {


                if (details.specifications[totalVolumeTag]) {

                    if (parseFloat(details.specifications[totalVolumeTag]) <= minVolume)
                    issues.push({ message: t('valid.invalid_totvol'), type: 'error', location: totalVolumeTag })

                    else if (parseFloat(details.specifications[totalVolumeTag]) > maxVolumeCV)
                        issues.push({ message: t('valid.invalid_totvol_range', { max: maxVolumeCV }), type: 'warning', location: totalVolumeTag })

                } 
            }

            // validity checks common to all types.

            // 12/24: we're raising errors now for both FVs and CVs, whereas we used raise only a warning for CVs. 

            if (details.range && !tagmap.id2tag(details.range).of(vesselRangeCategory))
                issues.push({ message: t('valid.invalid_range', { range: details.range }), type: 'error', location: vesselRangeCategory });


            (details.gears ?? []).filter(g => !tagmap.id2tag(g).of(vesselGearCategory)).forEach(gear => issues.push({ message: t('valid.invalid_gear', { gear }), type: 'error', location: gear }))





            //----- contacts ------------------------------------------------------------------------------------------------------------------------------------

            // carriers require only owner and operator types, other vessels require all contact types.
            const mandatoryContact = (contact: Contact) => isFishingVessel(patch) ? true : [ownerTag, operatorTag].includes(contact.type)

            details.contacts.filter(mandatoryContact).forEach(contact => {

                if (!contact.name)
                    issues.push({ message: t('valid.missing_contact_name', { name: tagmap.id2name(contact.type).of(contactTypeCategory) }), type: 'error', location: `${contact.type}-name` })

                if (!contact.address)
                    issues.push({ message: t('valid.missing_contact_address', { name: tagmap.id2name(contact.type).of(contactTypeCategory) }), type: 'error', location: `${contact.type}-address` })

                if (isOperatingCompany(contact) && !contact.regno)
                    issues.push({ message: t('valid.missing_company_regno', { name: tagmap.id2name(contact.type).of(contactTypeCategory) }), type: 'error', location: `${contact.type}-regno` })

            })


            return { ...acc, [patch.id]: issues }

        }, {})


    }
}


